<template>
    <el-dialog width="830px" :visible.sync="innerVisible" title="获奖信息">
        <div class="experience-list">
            <div class="experience-item" v-for="(item, index) in reward" :key="item.identifier">
                <div class="item-block">
                    <span class="label">获奖时间</span>
                    <el-date-picker
                        v-model="item.got_time"
                        size="small"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="开始时间"
                    >
                    </el-date-picker>
                </div>
                <div class="item-block">
                    <span class="label">奖项</span>
                    <el-select size="small" v-model="item.award">
                        <el-option label="红点奖" value="1"></el-option>
                        <el-option label="IF" value="2"></el-option>
                        <el-option label="IDEA" value="3"></el-option>
                        <el-option label="G-Mark" value="4"></el-option>
                        <el-option label="红星" value="5"></el-option>
                        <el-option label="中国好设计奖" value="6"></el-option>
                        <el-option label="其他" value="10"></el-option>
                    </el-select>
                </div>
                <div class="item-block">
                    <span class="label">获奖作品名称</span>
                    <el-input size="small" v-model="item.detail"></el-input>
                </div>
                <el-button
                    style="margin-left: 5px"
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    class="addBtn"
                    @click="handleAdd(index)"
                ></el-button>
                <el-button
                    v-show="reward.length > 1"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleRemove(index)"
                ></el-button>
            </div>
        </div>
        <div style="text-align: right; margin-top: 20px">
            <el-button :loading="loading" type="primary" size="small" @click="handleSave"
                >保存修改</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { putDestgnerReward } from '@/api/recruit';

export default {
    name: 'add-reward',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        data: Array,
    },
    computed: {
        innerVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            },
        },
    },
    watch: {
        data: {
            handler() {
                if (this.data.length) {
                    this.reward = JSON.parse(JSON.stringify(this.data));
                }
            },
        },
    },
    data() {
        return {
            loading: false,

            reward: [
                {
                    got_time: '',
                    detail: '',
                    award: '',
                },
            ],
        };
    },
    methods: {
        handleAdd(index) {
            this.reward.splice(index + 1, 0, {
                got_time: '',
                detail: '',
                award: '',
                identifier: Date.now() + '-' + this.reward.length,
            });
        },

        handleRemove(index) {
            this.reward.splice(index, 1);
        },

        handleSave() {
            // 格式化数据
            this.loading = true;
            putDestgnerReward(this.reward).then(([, error]) => {
                if (!error) {
                    this.$message.success('保存成功');
                    this.$emit('saved');
                    this.$emit('update:visible', false);
                } else {
                    this.$message.error(error.msg);
                }
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.experience-item {
    .item-block {
        display: inline-block;
        line-height: 32px;
        margin-right: 10px;
        .label {
            margin-right: 10px;
        }
    }
    .el-input {
        width: 160px;
    }
    .el-date-editor {
        width: 135px;
    }
}
</style>
