<template>
    <div class="apply-container">
        <!-- 主要内容 -->
        <div class="apply-main">
            <!-- <div class="condition-title">
                {{ describe.title }}
            </div> -->
            <!-- 入围条件 -->
            <div class="condition-content">
                <p class="condition-title">优先入围条件</p>
                <ul v-if="describe">
                    <li
                        class="main-list"
                        v-for="(item, index) in describe.describe[1005].split('\n')"
                        :key="index"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
            <!-- 报名信息 -->
            <div class="apply-info">
                <div class="apply-info-title">
                    请阐明您的优势
                    <span class="info-tips">（完善资料增加优势，优势越多入选几率越高）</span>
                </div>
                <div class="apply-info-form">
                    <!-- 工作状态 -->
                    <div class="apply-info-form-item">
                        <div class="form-item-top">
                            <span class="form-item-label">您是个人设计师/设计工作室</span>
                        </div>
                        <div class="form-item-content">
                            <el-radio-group v-model="data.work_state">
                                <el-radio
                                    v-for="(text, value) in DESIGNER_WORK_STATE"
                                    :key="value"
                                    :label="value"
                                >
                                    {{ text }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <!-- 设计工作经验 -->
                    <div class="apply-info-form-item">
                        <div class="form-item-top">
                            <span class="form-item-label">设计工作经验</span>
                            <el-button type="text" @click="addWorkVisible = true">
                                修改>
                            </el-button>
                        </div>
                        <div class="form-item-content">
                            <el-checkbox v-model="workAgeDisplay">{{ data.age }}年</el-checkbox>
                        </div>
                    </div>
                    <!-- 获得奖项 -->
                    <div class="apply-info-form-item">
                        <div class="form-item-top">
                            <span class="form-item-label">获得奖项</span>
                            <el-button type="text" @click="addAwardVisible = true">修改></el-button>
                        </div>
                        <div class="form-item-content">
                            <el-checkbox-group v-model="data.reward">
                                <el-checkbox
                                    v-for="item in basicData.reward"
                                    :label="item.id"
                                    :key="item.id"
                                >
                                    {{ REWARD_TYPE[item.award] }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <!-- 相关案例作品 -->
                    <div class="apply-info-form-item">
                        <div class="form-item-top">
                            <span class="form-item-label">相关案例作品</span>
                            <el-button type="text" @click="uploadOpusVisible = true">
                                上传作品>
                            </el-button>
                        </div>
                        <div class="form-item-content">
                            <el-checkbox-group v-model="data.opus" :max="5">
                                <el-checkbox
                                    v-for="item in opusList"
                                    :label="item.id"
                                    :key="item.id"
                                >
                                    {{ item.name }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>

                    <!-- 个人描述 -->
                    <div class="apply-info-form-item">
                        <div class="form-item-top">
                            <span class="form-item-label">除上述情况外您还有哪些优势</span>
                        </div>
                        <div class="form-item-content">
                            <el-input
                                v-model="data.content"
                                type="textarea"
                                :rows="5"
                                :maxlength="200"
                                show-word-limit
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 操作 -->
            <div class="main-footer">
                <el-button @click="handleGoBack">返回</el-button>
                <el-button type="primary" :loading="loading" @click="handleApply">
                    提交报名
                </el-button>
            </div>
        </div>
        <!-- 自我介绍 -->
        <div class="apply-intro">
            <div class="apply-intro-title">将为您生成一段自我介绍</div>
            <p v-show="data.work_state" class="apply-intro-item">
                我是<span>{{ DESIGNER_WORK_STATE[data.work_state] }}</span>
            </p>
            <p v-show="workAgeDisplay" class="apply-intro-item">
                拥有<span>{{ data.age }}年</span>工作经验
            </p>
            <p v-show="rewardText" class="apply-intro-item">
                获得过<span>{{ rewardText }}</span>
            </p>
            <p v-show="OpusText" class="apply-intro-item">
                设计过相关作品<span>{{ OpusText }}</span>
            </p>
            <p v-show="data.content" class="apply-intro-item">
                {{ data.content }}
            </p>
        </div>

        <add-work :visible.sync="addWorkVisible" :data="basicData.work" @saved="init"></add-work>
        <add-reward
            :visible.sync="addAwardVisible"
            :data="basicData.reward"
            @saved="init"
        ></add-reward>

        <!-- 上传作品详情框 -->
        <lk-dialog
            :visible.sync="uploadOpusVisible"
            :closeOnClickModal="false"
            title="上传作品"
            @confirm="handleUploadOpus"
        >
            <upload-opus
                v-if="opusRefreshFlag"
                ref="uploadOpus"
                :tags="opusTags"
                :category="opusCategory"
            ></upload-opus>
        </lk-dialog>
    </div>
</template>
<script>
import {
    getUserApplySettings,
    getUserOpusList,
    applyRecruit,
    getRecruitInfo,
    getCategoryOpus,
    getAllTag,
    postOpus,
} from '@/api/recruit';

import LkDialog from '@/components/LkDialog.vue';

import AddWork from './components/AddWork.vue';
import AddReward from './components/AddReward.vue';
import UploadOpus from './components/UploadOpus.vue';

import { DESIGNER_WORK_STATE, REWARD_TYPE } from '@/constant/recruit';

export default {
    name: 'recruit-apply',
    components: { LkDialog, AddWork, AddReward, UploadOpus },
    computed: {
        id() {
            return this.$route.params.id;
        },
        // 是否显示工作年龄
        workAgeDisplay: {
            get() {
                return this.data.work_age_display === 1;
            },
            set(value) {
                this.data.work_age_display = value ? 1 : 0;
            },
        },
        // 设计过的作品
        OpusText() {
            const selected = this.opusList.filter((item) => {
                return this.data.opus.indexOf(item.id) > -1;
            });
            return selected.map((item) => item.name).join('，');
        },
        // 获得过的奖项
        rewardText() {
            const selected = this.basicData.reward.filter((item) => {
                return this.data.reward.indexOf(item.id) > -1;
            });
            return selected.map((item) => REWARD_TYPE[item.award]).join('，');
        },
        // 上传作品一级二级分类
        // 将后端数据整理成{id:{label:"",children:{id:{label:""}}}} 的格式
        opusCategory() {
            let category = {};
            Object.keys(this.opusCategoryOrigin).forEach((key) => {
                category[key] = {
                    label: this.opusCategoryOrigin[key].name,
                    children: {},
                };

                this.opusCategoryOrigin[key].sub_category.forEach((subCategory) => {
                    category[key].children[subCategory.id] = { label: subCategory.name };
                });
            });
            return category;
        },
    },
    data() {
        return {
            loading: false,
            // 服务端返回数据
            basicData: {
                work: [],
                reward: [], // 奖项
            },
            opusList: [], // 个人作品集合
            data: {
                // 设计师工作现状
                // 1 在职设计师, 2 学生, 3 自由职业者 4 工作室
                work_state: '1',
                opus: [], // 相关案例作品
                reward: [], // 奖项
                age: 1, // 工作年限
                content: '', // 描述个人优势
                work_age_display: 0, // 是否展示工作年龄 0 不展示 1展示
            },

            addWorkVisible: false,
            addAwardVisible: false,
            uploadOpusVisible: false, //上传作品详情弹框是否可见

            DESIGNER_WORK_STATE,
            REWARD_TYPE,

            describe: '', // 入围条件
            opusTags: {}, // 标签
            opusCategoryOrigin: {}, // 一级分类，二级分类
            opusRefreshFlag: true, // 当提交opus表单后，重置这个标识位，让表单页面重置
        };
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getUserApplySettings();
            this.getUserOpusList();
            this.initRecruitInfo();
            this.getCategoryOpus();
            this.getAllTag();
        },

        // 获取招募单详情
        async initRecruitInfo() {
            const [response, error] = await getRecruitInfo(this.id);
            if (!error) {
                this.describe = response.data;
            } else {
                this.$message.error(error.msg);
            }
        },
        // 获取一级二级分类
        getCategoryOpus() {
            getCategoryOpus().then(([response, error]) => {
                if (!error) this.opusCategoryOrigin = response.data;
                else {
                    console.warn(response.msg);
                }
            });
        },

        // 初始化设计师信息
        getUserApplySettings() {
            getUserApplySettings().then(([response, error]) => {
                if (!error) {
                    this.basicData = response.data;
                    this.data.age = response.data.age;
                }
            });
        },

        // 获取个人作品
        getUserOpusList() {
            getUserOpusList().then(([response, error]) => {
                if (!error) {
                    this.opusList = response.data;
                }
            });
        },

        // 获取标签
        getAllTag() {
            getAllTag().then(([response, error]) => {
                if (!error) this.opusTags = response.data;
            });
        },

        handleApply() {
            this.loading = true;
            applyRecruit(this.id, this.data).then(([, error]) => {
                if (!error) {
                    this.$message.success('报名成功');
                    this.handleGoBack();
                } else {
                    this.$message.error(error.msg);
                }
                this.loading = false;
            });
        },

        handleGoBack() {
            this.$router.push(`/recruit/${this.id}`);
        },

        // 处理提交上传作品
        handleUploadOpus(done, cancel) {
            // 同步oss文件列表到form
            let syncStatus = this.$refs.uploadOpus.syncUploadFormData();
            if (!syncStatus) {
                this.$message({
                    type: 'warning',
                    message: '请稍等，文件正在上传中',
                });
                cancel();
                return;
            }
            // 表格校验
            this.$refs.uploadOpus.$refs.uploadForm.validate((valid) => {
                if (!valid) {
                    cancel();
                    return;
                }
                this.postOpus(done, cancel, this.$refs.uploadOpus.uploadForm);
            });
        },
        // 上传作品并更新作品列表
        postOpus(done, cancel, opusForm) {
            postOpus(opusForm).then(([, error]) => {
                if (!error) {
                    this.$message({
                        type: 'success',
                        message: '上传成功',
                    });
                    this.opusRefreshFlag = false;
                    this.$nextTick(() => {
                        this.opusRefreshFlag = true;
                    });
                    this.getUserOpusList();
                    done();
                } else {
                    this.$message({
                        type: 'error',
                        message: error,
                    });
                    cancel();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.apply-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 40px 90px;
    border-radius: 30px;
    background-color: #fff;
    // 左侧主区域
    .apply-main {
        width: 675px;
        .condition-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            margin-bottom: 20px;
        }
        // 入围条件
        .condition-content {
            padding: 20px 24px;
            background: #f6f6f6;
            border-radius: 4px;
            margin-bottom: 20px;

            .condition-title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            .main-list {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 6px;
            }
        }
        // 报名信息
        .apply-info {
            &-title {
                font-size: 18px;
                font-weight: bold;
                .info-tips {
                    font-size: 14px;
                    color: #999;
                }
            }
            // 报名信息表单
            &-form {
                margin-top: 20px;
                &-item {
                    margin-bottom: 35px;
                    .form-item-top {
                        display: flex;
                        justify-content: space-between;
                        height: 40px;
                        line-height: 40px;
                        padding-bottom: 5px;
                    }
                    .form-item-label {
                        display: block;
                        font-size: 14px;
                        color: #666;
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .main-footer {
            text-align: right;
        }
    }
    .apply-intro {
        position: absolute;
        right: 90px;
        top: 40px;
        bottom: 40px;
        width: 294px;
        background-color: #fff8e5;
        padding: 60px 28px 0;
        color: #333;
        &-title {
            font-size: 18px;
            margin-bottom: 14px;
            font-weight: bold;
        }
        &-item {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 8px;
            span {
                font-size: 16px;
                font-weight: bold;
                margin: 0 4px;
            }
        }
    }
    .el-checkbox-group {
        line-height: 25px;
    }
}
</style>
