<template>
    <div class="recruit-container" v-if="data">
        <!-- 内容主体 -->
        <div class="recruit-main">
            <!-- header -->
            <div class="recruit-header">
                <!-- 功能区 -->
                <div class="recruit-shop">
                    <!-- 标题 -->
                    <div class="header-info">
                        <p class="info-major">
                            {{ data.title }}
                            <span>
                                {{ data.task_pk_type === 0 ? 'PK' : '无需PK' }}
                            </span>
                            <span v-for="(item, index) in data.tag" :key="index">
                                {{ item == 1 ? '国内知名企业' : '世界500强' }}
                            </span>
                        </p>
                        <p class="info-major">￥{{ data.price | price }}</p>
                    </div>
                    <!-- 周期 -->
                    <div class="header-cycle">
                        <p class="cycle-time">
                            <span>{{ data.track_label }}</span>
                            <span> 项目周期：{{ taskTime }} </span>
                            <span>报名时间剩余 {{ surplusTime(data.close_time) }}</span>
                        </p>
                        <p>已报名：{{ data.enroll_num }}人</p>
                    </div>
                </div>
                <!-- 进度条 -->
                <div class="recruit-step">
                    <div class="stop-box">
                        <div class="step-time">
                            <p v-if="data.release_time">
                                {{ dateFormat(strToTimestamp(data.release_time), 'yyyy-MM-dd') }}
                            </p>
                            <p v-if="data.close_time">
                                {{ dateFormat(strToTimestamp(data.close_time), 'yyyy-MM-dd') }}
                            </p>
                            <p v-if="data.end_time">
                                {{ dateFormat(strToTimestamp(data.end_time), 'yyyy-MM-dd') }}
                            </p>
                        </div>
                        <p class="step-content">
                            <span
                                class="dot-step"
                                :class="{
                                    'status-start': statusStart(),
                                }"
                            ></span>
                            <span
                                class="line-step"
                                :class="{
                                    'status-close': statusClose(),
                                }"
                            ></span>
                            <span
                                class="dot-step"
                                :class="{
                                    'status-close': statusClose(),
                                }"
                            ></span>
                            <span
                                class="line-step"
                                :class="{
                                    'status-end': statusEnd(),
                                }"
                            ></span>
                            <span
                                class="dot-step"
                                :class="{
                                    'status-end': statusEnd(),
                                }"
                            ></span>
                        </p>
                        <div class="step-time">
                            <p>招募开始</p>
                            <p>报名截止</p>
                            <p>招募结束</p>
                        </div>
                    </div>
                    <!-- 报名状态 -->
                    <div>
                        <!-- 状态 0 待提交 1 待审核 2 待修改 3 待发布 4 招募中 5 选择中 6 招募结束 7 已取消 -->
                        <!-- 报名截止：报名按钮不显示，报名状态为null：按钮不显示 -->

                        <el-button
                            v-if="data.status <= 4 && recruitStatus === null"
                            type="primary"
                            size="small"
                            @click="handleApply"
                        >
                            我要报名
                        </el-button>

                        <div v-else>
                            <p>报名状态：{{ myRecruitStatus(data.status, recruitStatus) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- content -->
            <div class="recruit-content mt-20">
                <div>
                    <p class="main-title">项目背景</p>
                    <p class="main-describe">
                        {{ data.describe[1001] }}
                    </p>
                </div>

                <i class="main-line"></i>

                <div>
                    <p class="main-title">风格要求</p>
                    <p class="main-describe">{{ data.describe[1002] }}</p>
                </div>

                <i class="main-line"></i>

                <div>
                    <p class="main-title">目标用户人群</p>
                    <p class="main-describe">
                        {{ data.describe[1003] }}
                    </p>
                </div>

                <i class="main-line"></i>

                <div>
                    <p class="main-title">相关资料</p>
                    <p
                        class="main-describe"
                        v-for="(item, index) in data.describe[1004]"
                        :key="index"
                    >
                        {{ item | formatFileName }}
                        <el-link
                            :href="$fillOssPath(item)"
                            target="_blank"
                            class="ml-10"
                            :underline="false"
                        >
                            下载
                        </el-link>
                    </p>
                </div>

                <i class="main-line"></i>

                <!-- 交付标准 -->
                <div>
                    <p class="main-title">交付标准</p>
                    <div class="main-describe">
                        设计交付物
                        <p class="main-design mt-8">
                            <span class="mr-30">初稿方案</span>
                            <span>终稿方案</span>
                        </p>
                    </div>
                    <div class="main-describe mt-15">
                        中标交付物
                        <p class="main-select mt-8">
                            <span class="mr-30">提案文件</span>
                            <span class="mr-30">设计效果稿</span>
                            <span>设计源文件</span>
                        </p>
                    </div>
                    <p class="mt-10 main-contract">(最终交付物内容以合同内为准)</p>
                </div>

                <i class="main-line"></i>

                <!-- 时间安排 -->
                <div>
                    <p class="main-title">时间安排</p>
                    <div class="main-describe">
                        <ul>
                            <li class="main-list" v-if="data.task_start_time">
                                预计启动时间：{{
                                    dateFormat(strToTimestamp(data.task_start_time), 'yyyy-MM-dd')
                                }}
                            </li>
                            <li class="main-list mt-6">项目周期： {{ taskTime }}</li>
                            <li class="main-contract mt-6">(最终时间以合同内为准)</li>
                        </ul>
                    </div>
                </div>

                <i class="main-line"></i>

                <!-- 入围条件 -->
                <div>
                    <p class="main-title">优先入围条件</p>
                    <div class="main-describe">
                        <ul>
                            <li
                                class="main-list"
                                v-for="(item, index) in data.describe[1005].split('\n')"
                                :key="index"
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <div class="recruit-footer mt-20">
                <div>
                    <div class="footer-title">
                        <span class="peoples">已报名设计师（ {{ pagination.total }} 人）</span>
                        <div>
                            <el-button
                                v-if="data.status <= 4 && recruitStatus === null"
                                type="primary"
                                size="small"
                                @click="handleApply"
                            >
                                我要报名
                            </el-button>

                            <div v-else>
                                <p>报名状态：{{ myRecruitStatus(data.status, recruitStatus) }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- 设计师列表 -->
                    <div v-if="designerList.length" class="footer-infoSum mt-8">
                        <div v-for="item in designerList" :key="item.id" class="footer-line">
                            <div class="footer-info">
                                <!-- 头像 -->
                                <div class="user-avatar">
                                    <img :src="item.head_image" alt="" />
                                </div>

                                <div class="footer-box ml-15">
                                    <!-- 设计师名称 -->
                                    <p class="footer-icon mt-10">
                                        <span>{{ item.name }}</span>
                                        <el-tag
                                            v-if="item.status === 1"
                                            class="ml-6"
                                            type="success"
                                            effect="dark"
                                            size="small"
                                        >
                                            已入选
                                        </el-tag>
                                    </p>

                                    <!-- 时间 -->
                                    <p class="footer-time mt-5">
                                        <span class="mr-10" v-if="item.created_at">
                                            {{
                                                dateFormat(
                                                    strToTimestamp(item.created_at),
                                                    'yyyy-MM-dd'
                                                )
                                            }}
                                        </span>
                                    </p>
                                    <!-- 重要信息 -->
                                    <div class="footer-img-list">
                                        <!-- 工作经验 -->
                                        <!-- 0 不展示 1 展示 -->
                                        <p v-if="item.work_age_display === 1">
                                            {{ item.age }}年设计工作经验
                                        </p>

                                        <!-- 相关作品 -->
                                        <p v-if="item.opus && item.opus.length">
                                            设计过相关作品：
                                            <span
                                                class="mr-5"
                                                v-for="name in item.opus"
                                                :key="name.id"
                                            >
                                                {{ name.name }}
                                            </span>
                                        </p>

                                        <!-- 获奖 -->
                                        <ul
                                            v-if="item.reward && item.reward.length"
                                            class="footer-reward"
                                        >
                                            <li>
                                                获得过:
                                                <span
                                                    class="mr-5"
                                                    v-for="rew in item.reward || []"
                                                    :key="rew.id"
                                                >
                                                    {{ REWARD_TYPE[rew.award] }}
                                                </span>
                                            </li>
                                        </ul>

                                        <!-- 简介 -->
                                        <ul class="footer-list" v-if="item.content">
                                            <li
                                                v-for="(lt, index) in item.content.split('\n')"
                                                :key="index"
                                            >
                                                {{ lt }}
                                            </li>
                                        </ul>

                                        <!-- 作品 -->
                                        <ul
                                            class="footer-imgBox"
                                            v-if="item.opus"
                                            style="display: flex"
                                        >
                                            <li v-for="opus in item.opus" :key="opus.id">
                                                <el-image
                                                    class="footer-img"
                                                    v-if="opus.status === 1 && opus.display === 1"
                                                    :src="opus.opus_cover"
                                                    alt=""
                                                    :preview-src-list="srcList(opus)"
                                                >
                                                </el-image>
                                                <div v-else class="img-mask">已保密</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <i class="main-line"></i>
                        </div>
                    </div>
                    <!-- 暂无数据 -->
                    <lk-empty v-else> </lk-empty>

                    <!-- 分页 -->
                    <lk-pagination
                        justify="center"
                        :page.sync="pagination.page"
                        :size.sync="pagination.size"
                        :total="pagination.total"
                        @change="getPage"
                        v-if="pagination.total > pagination.size"
                    ></lk-pagination>
                </div>
            </div>
        </div>
        <!-- 侧边栏 -->
        <div class="recruit-sidebar">
            <div class="recruit-info">
                <img class="sidebar-portrait" src="@/assets/images/portrait.png" alt="" />
                <p class="sidebar-service">邦女郎全程服务保障</p>
                <div class="sidebar-tag">
                    <span>无需商务对接</span>
                    <span>任务需求有保障</span>
                </div>
                <p class="mt-18 declaration">
                    个人宣言：期待和每一位优质设计师合作，共同打造行业爆款产品。
                </p>
            </div>
            <div class="recruit-column">
                <p class="column-title">任务流程指南</p>
                <div class="recruit-box mt-20">
                    <ul class="column-list">
                        <li><span class="recruit-number">1</span>招募入围，签订协议</li>
                        <li class="mt-12"><span class="recruit-number">2</span>设计并提交方案</li>
                        <li class="mt-12"><span class="recruit-number">3</span>深化设计方案</li>
                        <li class="mt-12"><span class="recruit-number">4</span>交付最终文件</li>
                        <li class="mt-12"><span class="recruit-number">5</span>任务酬金提现</li>
                    </ul>
                    <div class="recruit-line"></div>
                </div>
            </div>
        </div>
        <!-- 取消报名弹窗 -->
        <!-- <el-dialog title="取消报名" :visible.sync="dialogVisible" width="500px">
            <span>取报名后将不可再此报名此任务，且不可被选中</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="handleCancel">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import LkEmpty from '@/components/LkEmpty.vue';
import LkPagination from '@/components/LkPagination.vue';
import { dateFormat, strToTimestamp } from '@/utils/common';
import { REWARD_TYPE, RECRUIT_INFO_TYPE, RECRUIT_STATUS } from '@/constant/recruit';
import {
    getRecruitInfo, // 招募单详情
    getDesignerList, // 报名设计师列表
    getRecruitStatus, // 报名状态查询
    getUserApplySettings, // 获取设计师个人报名所用的相关信息
} from '@/api/recruit';
export default {
    name: 'recruit-detail',
    components: {
        LkEmpty,
        LkPagination,
    },

    data() {
        return {
            isDesigner: false, // 是否是设计师

            designerList: [], // 设计师列表
            data: {
                describe: {
                    1005: '',
                },
            },
            recruitStatus: '', // 报名状态
            pagination: {
                page: 1,
                size: 5,
                total: 0,
            },
            address: '', // 地址
            dialogVisible: false, // 取消报名弹窗默认不显示

            REWARD_TYPE,
            RECRUIT_INFO_TYPE,
            RECRUIT_STATUS,
        };
    },

    computed: {
        id() {
            return this.$route.params.id;
        },

        // 任务周期
        taskTime() {
            if (!this.data.task_start_time || !this.data.task_end_time) return '--';
            let taskTime = strToTimestamp(this.data.task_start_time);
            let endTime = strToTimestamp(this.data.task_end_time);
            let times = Math.ceil((endTime - taskTime) / 864e5);
            return times <= 0 ? '--' : times + '天';
        },
    },

    mounted() {
        this.initData();
    },

    methods: {
        dateFormat,

        strToTimestamp,

        // 初始化数据
        initData() {
            // 获取招募详情，目前缺少设计师身份标识字段，暂时通过此接口判断是否是设计师
            this.initRecruitInfo();
            this.initDesignerList();
            this.initRecruitStatus();
        },

        srcList(img) {
            let arr = [];
            for (let i = 0; i < img.images.length; i++) {
                arr.push(img.images[i].image_url);
            }
            return arr;
        },

        // 招募转态
        myRecruitStatus(val, item) {
            if (val === 6 && item === 0) {
                return '未入选';
            }
            return RECRUIT_STATUS[item];
        },

        // 招募开始
        statusStart() {
            // 状态 0 待提交 1 待审核 2 待修改 3 待发布 4 招募中 5 选择中 6 招募结束 7 已取消
            return [3, 4, 5, 6, 7].indexOf(this.data.status) !== -1;
        },

        // 报名截止
        statusClose() {
            return [5, 6, 7].indexOf(this.data.status) !== -1;
        },

        // 招募结束
        statusEnd() {
            return [6, 7].indexOf(this.data.status) !== -1;
        },

        // 剩余天数
        surplusTime(time) {
            if (!time) return '--';
            let nowTime = +new Date();
            let endTime = strToTimestamp(time);
            let times = Math.ceil((endTime - nowTime) / 864e5);
            return times <= 0 ? '--' : times + '天';
        },

        // 设计师列表
        async initDesignerList() {
            const { page, size } = this.pagination;
            const params = { page, size };
            const [response, error] = await getDesignerList(this.id, params);
            if (!error) {
                this.pagination.total = response.data.total;
                this.designerList = response.data.data;
            } else {
                this.$message.error(error.msg);
            }
        },

        // 获取招募单详情
        async initRecruitInfo() {
            const [response, error] = await getRecruitInfo(this.id);
            if (!error) {
                this.data = response.data;
                this.isDesigner = true;
            }
        },

        // 获取报名状态
        async initRecruitStatus() {
            const [response, error] = await getRecruitStatus(this.id);
            if (!error) {
                this.recruitStatus = response.data;
            } else {
                this.$message.error(error.msg);
            }
        },

        // 跳转到报名页面
        async handleApply() {
            const [, error] = await getUserApplySettings();
            if (!error) {
                this.$router.push({ name: 'recruit-apply' });
            } else if (error.code === 300007) {
                this.$confirm('您还不是设计师，请先认证为设计师后再参与报名', {
                    confirmButtonText: '前往认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    window.open(process.env.VUE_APP_PC_HOST + '/bond-recruit');
                });
            }
        },

        // 获取页码
        getPage() {
            this.initDesignerList();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variable.scss';
.recruit-container {
    display: flex;
    padding: 40px;
    .recruit-main {
        // flex: 1;
        width: 920px;
        .recruit-header {
            border-radius: 30px;
            padding: 40px 60px;
            background-color: #fff;
            // 功能区
            .recruit-shop {
                .header-info {
                    display: flex;
                    justify-content: space-between;
                    .info-major {
                        line-height: 28px;
                        font-size: 20px;
                        font-weight: bold;
                        height: 28px;
                        span {
                            display: inline-block;
                            padding: 0 12px;
                            height: 22px;
                            line-height: 22px;
                            margin-left: 10px;
                            background: #f9efda;
                            font-size: 12px;
                            color: #8e6f2c;
                        }
                    }
                }
                .header-cycle {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    line-height: 22px;
                    .cycle-time {
                        span {
                            margin-right: 30px;
                        }
                    }
                }
            }
            // 进度条
            .recruit-step {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 52px;
                .stop-box {
                    width: 430px;
                    .step-time {
                        display: flex;
                        justify-content: space-between;
                        line-height: 20px;
                        font-size: 14px;
                    }
                    .step-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 10px 0;
                        .dot-step {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            margin: 0 2px;
                            background: rgba(0, 0, 0, 0.1);
                        }
                        .line-step {
                            width: 200px;
                            height: 6px;
                            background: rgba(0, 0, 0, 0.1);
                        }
                        // 招募开始
                        &.step-content .status-start {
                            background: #679df8;
                        }
                        // 报名截止
                        &.step-content .status-close {
                            background: #679df8;
                        }
                        // 招募结束
                        &.step-content .status-end {
                            background: #679df8;
                        }
                    }
                }
            }
        }
        .recruit-content {
            border-radius: 30px;
            padding: 40px 60px;
            background: #fff;

            .main-title {
                position: relative;
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                &::after {
                    position: absolute;
                    top: 4px;
                    left: -8px;
                    content: '';
                    display: block;
                    width: 2px;
                    height: 14px;
                    background: #679df8;
                }
            }
            .main-describe {
                font-size: 14px;
                line-height: 20px;
                margin-top: 10px;
                text-align: justify;
                .main-design {
                    span {
                        position: relative;
                        padding-left: 20px;
                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 17px;
                            margin-top: -7px;
                            content: '';
                            display: block;
                            width: 14px;
                            height: 14px;
                            background: url('~@/assets/images/fa-icon.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }

                .el-link {
                    color: #679df8;
                }

                .main-design,
                .main-select {
                    span {
                        display: inline-block;
                        height: 30px;
                        width: 100px;
                        line-height: 30px;
                        border: 1px solid #acacac;
                        border-radius: 4px;
                        font-size: 12px;
                        text-align: center;
                        color: #888;
                    }
                }
                .main-list {
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 6px;
                }
            }

            .main-contract {
                font-size: 14px;
                line-height: 20px;
                color: #888;
            }
        }
        .main-line {
            display: block;
            height: 1px;
            border: 1px solid #fafafa;
            margin: 18px auto;
        }
        .recruit-footer {
            border-radius: 30px;
            padding: 40px 60px;
            background: #fff;
            .footer-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .peoples {
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
            .footer-line {
                &:last-child {
                    .main-line {
                        display: none;
                    }
                }
                .footer-info {
                    display: flex;
                    .user-avatar {
                        width: 60px;
                        height: 60px;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }

                    .footer-box {
                        flex: 1;
                        .footer-time {
                            font-size: 12px;
                            line-height: 16px;
                            color: #888;
                        }
                        .footer-reward {
                            line-height: 20px;
                            font-size: 14px;
                        }
                        .footer-icon {
                            display: flex;
                            span {
                                line-height: 24px;
                            }
                        }
                        .footer-list {
                            line-height: 20px;
                            font-size: 14px;
                        }
                        .img-mask {
                            width: 100%;
                            height: 100%;
                            line-height: 120px;
                            text-align: center;
                            background: #333333;
                            color: #fff;
                        }
                        .footer-img-list {
                            margin-top: 20px;
                            font-size: 14px;
                            line-height: 20px;
                            .footer-imgBox {
                                margin-top: 10px;
                                display: flex;
                                flex-wrap: wrap;
                                li {
                                    margin: 0 10px 10px 0;
                                    width: 200px;
                                    height: 120px;
                                    .footer-img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 侧边栏
    .recruit-sidebar {
        width: 250px;
        margin-left: 20px;
        .recruit-info {
            width: 250px;
            padding: 30px 20px;
            background-color: #fff;
            border-radius: 30px;
            .sidebar-portrait {
                display: block;
                margin: 0 auto;
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
            .sidebar-service {
                margin-top: 10px;
                line-height: 20px;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
            }
            .sidebar-tag {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                span {
                    width: 100px;
                    height: 30px;
                    line-height: 30px;
                    border: 1px solid #679df8;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 4px;
                    color: #679df8;
                }
            }
            .declaration {
                font-size: 12px;
                line-height: 18px;
                text-align: center;
            }
        }
        .recruit-column {
            width: 250px;
            margin-top: 20px;
            padding: 30px 45px;
            border-radius: 30px;
            background-color: #fff;
            .column-title {
                line-height: 22px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
            }
            .recruit-box {
                position: relative;
                .column-list {
                    position: relative;
                    z-index: 2;
                    li {
                        line-height: 18px;
                        font-size: 14px;
                        .recruit-number {
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            line-height: 16px;
                            border: 1px solid #999;
                            border-radius: 50%;
                            font-size: 12px;
                            text-align: center;
                            color: #999999;
                            background: #fff;
                            margin-right: 10px;
                        }
                    }
                }
                .recruit-line {
                    position: absolute;
                    top: 17px;
                    left: 8px;
                    width: 1px;
                    height: 112px;
                    background: #999999;
                }
            }
        }
    }
    .footer-infoSum {
        margin-top: 20px;
    }
}
</style>
