<template>
    <el-dialog width="1000px" :visible.sync="innerVisible" title="工作经验">
        <div class="experience-list">
            <div class="experience-item" v-for="(item, index) in work" :key="item.identifier">
                <div class="item-block" style="width: 480px">
                    <span class="label">开始时间</span>
                    <el-date-picker
                        v-model="item.start_time"
                        :picker-options="genStartPickerOptions(item)"
                        size="small"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="开始时间"
                    >
                    </el-date-picker>
                    <span class="label" style="margin-left: 10px">结束时间</span>
                    <el-date-picker
                        v-model="item.end_time"
                        :picker-options="genEndPickerOptions(item)"
                        v-if="!item.isToNow"
                        size="small"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="结束时间"
                    >
                    </el-date-picker>
                    <el-checkbox v-model="item.isToNow" style="margin-left: 10px">至今</el-checkbox>
                </div>
                <div class="item-block">
                    <span class="label">公司</span>
                    <el-input size="small" v-model="item.company"></el-input>
                </div>
                <div class="item-block">
                    <span class="label">职位</span>
                    <el-input size="small" v-model="item.position"></el-input>
                </div>
                <el-button
                    style="margin-left: 5px"
                    type="text"
                    icon="el-icon-circle-plus-outline"
                    class="addBtn"
                    @click="handleAddWork(index)"
                ></el-button>
                <el-button
                    v-show="work.length > 1"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDeleWork(index)"
                ></el-button>
            </div>
        </div>
        <div style="text-align: right; margin-top: 20px">
            <el-button :loading="loading" type="primary" size="small" @click="handleSave"
                >保存修改</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
import { putDesignerWork } from '@/api/recruit';

export default {
    name: 'add-work',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        data: Array,
    },
    computed: {
        innerVisible: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            },
        },
    },
    watch: {
        data: {
            handler() {
                if (this.data.length) {
                    this.work = this.data.map((item, index) => {
                        return {
                            ...item,
                            identifier: Date.now() + '-' + index,
                            isToNow: item.end_time === '0000-00-00',
                            end_time: item.end_time === '0000-00-00' ? '' : item.end_time,
                        };
                    });
                }
            },
        },
    },
    data() {
        return {
            loading: false,

            work: [
                {
                    start_time: '',
                    end_time: '',
                    company: '',
                    position: '',
                    identifier: 1, // 新增工作经历的时候，添加一个额外的属性，以确保key的唯一性
                    isToNow: false,
                },
            ],
        };
    },
    methods: {
        /*
            生成开始时间的限制规则。
            1. 如果已经设置了结束时间，则开始时间必须小于结束时间
            2. 未设置结束时间，则开始时间必须小于当前时间
        */
        genStartPickerOptions(item) {
            return {
                disabledDate: (date) => {
                    // 开始时间
                    const today = new Date(new Date().toLocaleDateString()).getTime();
                    const lastTime =
                        !item.end_time || item.end_time === '0000-00-00'
                            ? today - 864e5
                            : new Date(item.end_time).getTime() - 864e5;
                    return date.getTime() > lastTime;
                },
            };
        },

        /*
            生成结束时间的限制规则。
            1. 如果已经设置了开始时间，则结束时间必须大于开始时间
            2. 结束时间必须小于当前时间
        */
        genEndPickerOptions(item) {
            return {
                disabledDate: (date) => {
                    const today = new Date(new Date().toLocaleDateString()).getTime();
                    if (item.start_time) {
                        const startTime = new Date(
                            new Date(item.start_time).toLocaleDateString()
                        ).getTime();
                        return date.getTime() < startTime + 864e5 || date.getTime() > today;
                    }
                    return date.getTime() > today;
                },
            };
        },

        handleAddWork(index) {
            this.work.splice(index + 1, 0, {
                start_time: '',
                end_time: '',
                company: '',
                position: '',
                identifier: Date.now() + '-' + this.work.length,
                isToNow: false,
            });
        },

        handleDeleWork(index) {
            this.work.splice(index, 1);
        },

        handleSave() {
            // 格式化数据
            const work = [];
            for (let i = 0; i < this.work.length; i++) {
                const now = this.work[i];
                const item = {
                    start_time: now.start_time,
                    end_time: now.isToNow ? '0000-00-00' : now.end_time,
                    company: now.company,
                    position: now.position,
                };
                if (!item.start_time || !item.end_time || !item.company || !item.position) {
                    return this.$message.error('请完成填写工作经历');
                }
                work.push(item);
            }
            this.loading = true;
            putDesignerWork(work).then(([, error]) => {
                if (!error) {
                    this.$message.success('保存成功');
                    this.$emit('saved');
                    this.$emit('update:visible', false);
                } else {
                    this.$message.error(error.msg);
                }
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.experience-item {
    .item-block {
        display: inline-block;
        line-height: 32px;
        margin-right: 10px;
        .label {
            margin-right: 10px;
        }
    }
    .el-input {
        width: 160px;
    }
    .el-date-editor {
        width: 135px;
    }
}
</style>
